






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LegalPDFViewer extends Vue {

  pdfUrl = '';

  // we will receive a url
  async beforeMount() {
    window.scrollTo(0, 0);

    switch (this.$route.name) {
      case 'Terms of Service': 
        this.pdfUrl = 'https://media.eazigear.co.za/legal/TERMS OF SERVICE.pdf';
        break;

      case 'Privacy Policy': 
        this.pdfUrl = 'https://media.eazigear.co.za/legal/Privacy Policy.pdf';
        break;

      case 'Trust & Safety': 
        this.pdfUrl = 'https://media.eazigear.co.za/legal/Trust & Safety.pdf';
        break;
    }
  }

}
